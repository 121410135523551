import { useEffect, useState, useContext } from "react";
import {
  Box,
  FormControl,
  Grid,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  MenuItem,
  Select,
  Typography,
  Skeleton,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import {
  createSearchParams,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import {
  CultureContext,
  ICultureContextType,
} from "../../Context/cultureContext";
import { BrowseContext, IBrowseContextType } from "../../Context/browseContext";
import { orderBy } from "lodash";

interface Props {
  brands: any;
  loading: boolean;
}
const ITEM_HEIGHT = 100;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 150,
    },
  },
};
export const Brand = () => {
  const { locale = "en", region = "France" } = useParams();
  const { loading, products, criteriaList } = useContext(BrowseContext) as IBrowseContextType;
  const navigate = useNavigate();
  let [searchParams] = useSearchParams();
  const selected = searchParams.get("brand") || "all";  
  const selectedListCriteria = searchParams.getAll("fl[]") || [];
  const selectedFormatCriteria =  searchParams.getAll("ff[]") || [];

  const { config, cultureLoading } = useContext(
    CultureContext
  ) as ICultureContextType;
  const { t } = useTranslation("common");
  const [lastSegment, setLastSegment] = useState<any>();

  useEffect(() => {
    const extractLastSegment = () => {
      const url = window.location.pathname + window.location.search;
      const lastSegmentfromURL = url.split("&").pop();
      setLastSegment(lastSegmentfromURL);
    };
    extractLastSegment();
  }, []);

  const premiumIncludedSearchParam = (brandLabel: string) => {
		const params = createSearchParams({
			brand: brandLabel,
      sort: "downloads",
      order: "desc",
		});
		
		// check for principal
		selectedListCriteria
			.filter((crit) => {
        const critId = crit.split("+")[0];

        return criteriaList.hasOwnProperty(critId) && criteriaList[critId].principal
      })
			.forEach((crit) => params.append("fl[]", crit));


    selectedFormatCriteria
			.forEach((crit) => params.append("ff[]", crit));
		return params;
	};

  if (loading || cultureLoading) {
    const n = 8;
    const brandSkeleton = [...Array(n)].map((i: any) => {
      return <Skeleton variant="rectangular" key={i} />;
    });


    return (
      <Typography component="div" className="brand-skeleton" style={{ padding: "0 10px" }}>
        {brandSkeleton}
      </Typography>
    );
  }

  // 1. Sorting downloads of "brands" array in ascending order
  // 2. reversing brands array in descending order

  if (products && config) {
    const orderBrands = orderBy(products.brandList, ["downloads"], ["desc"]);

    let indexinuse = orderBrands.findIndex((brandIndex: any) => {
      return brandIndex.label.toLowerCase() === selected.toLowerCase();
    });

    indexinuse = indexinuse === -1 ? 0 : indexinuse + 1;
    return (
      <Grid item lg={12} md={12} sm={12} xs={12}>
        <Typography component="div" className="chip-container">
          <Box sx={{ flexGrow: 1 }}>
            <List className="browseBrands">
              <ListItem
                disablePadding
                id="listBrand"
                className={
                  indexinuse > 7 || indexinuse === 0
                    ? "Active brand-chip-button"
                    : "InActive brand-chip-button"
                }
              >
                <FormControl className="filterDropdown">
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={selected}
                    label="brands"
                    className="chip-brand"
                    IconComponent={() => (
                      <KeyboardArrowDownIcon className="sort-icon" />
                    )}
                    MenuProps={MenuProps}
                  >
                    <MenuItem
                      value={"brand"}
                      disabled
                      selected
                      sx={{
                        visibility: "hidden",
                        p: "0",
                        m: "0",
                        height: "20px",
                      }}
                      className={"menu-item"}
                      id="brandMenu"
                    >
                      {t("browse_page.brands")}
                    </MenuItem>

                    <MenuItem
                      value={"all"}
                      onClick={() => {
                        navigate(
                          `/${locale}/${region}/browse?${createSearchParams({
                            brand: "all",
                            sort: "downloads",
                            order: "desc",
                          })}`
                        );
                      }}
                      className={
                        selected === "all"
                          ? "Active all-brand-btn menu-item"
                          : "InActive all-brand-btn menu-item"
                      }
                      id="allBrands"
                    >
                      {t("browse_page.all_brands")}
                    </MenuItem>
                    {orderBrands &&
                      orderBrands.map((brand: any, i: any) => {
                        return (
                          <MenuItem
                            key={`${brand.oid}-${i}`}
                            value={brand.label}
                            onClick={() => {
                              if (brand.oid) {
                                const brandName = brand.oid;
                                localStorage.setItem("currentBrand", brandName);
                              }
                              navigate(
                                `/${locale}/${region}/browse?${createSearchParams(
                                  {
                                    brand: brand.label,
                                    sort: "downloads",
                                    order: "desc",
                                  }
                                )}`
                              );
                            }}
                            className={
                              selected === brand.label
                                ? "Active menu-item"
                                : "Inactive menu-item"
                            }
                            id={`allbrand-${brand.oid}-${i}`}
                          >
                            <span className="brandname">
                              {t("browse_page.brands")}
                            </span>

                            <img
                              src={`${config.REACT_APP_BIMDATABASE_API_URL}${brand.thumbnailImage}`}
                              alt={brand.label}
                              width="70px"
                            />
                          </MenuItem>
                        );
                      })}
                  </Select>
                </FormControl>
              </ListItem>

              {orderBrands &&
                orderBrands.slice(0, 7).map((brand: any, index: number) => {
                  return (
                    <ListItem
                      disablePadding
                      key={`${brand.oid}-${index}`}
                      onClick={() => {
                       
                        if (brand.oid) {
                          const brandName = brand.oid;
                          localStorage.setItem("currentBrand", brandName);
                        }
                        if (brand.label === searchParams.get("brand")) {
                          navigate(
                            `/${locale}/${region}/browse?${premiumIncludedSearchParam("all")}`
                          );
                        } else {
                          // navigate( //changed to like drop down menu
                          //   `/${locale}/${region}/browse?${premiumIncludedSearchParam(brand.label)}`
                          // );
                          navigate(
                            `/${locale}/${region}/browse?${createSearchParams(
                              {
                                brand: brand.label,
                                sort: "downloads",
                                order: "desc",
                              }
                            )}`
                          );
                        }
                      }}
                      className={
                        selected === brand.label ? "Active" : "Inactive"
                      }
                      id={`brands-${brand.oid}-${index}`}
                    >
                      <ListItemButton className="chip-brand" id="brandsList">
                        <ListItemText>{brand.label}</ListItemText>
                      </ListItemButton>
                    </ListItem>
                  );
                })}
            </List>
          </Box>
        </Typography>
      </Grid>
    );
  }

  return <></>;
};
