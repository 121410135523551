import React, { useEffect, useState } from "react";
import "../../Utils/assets/languageMenu.scss";
import { FormControl, Select, MenuItem, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import Cookies from "universal-cookie";
import { useParams } from "react-router-dom";
import { useRelocate } from "../../relocate";
import { MyConfig } from "../../API/myConfig";
import { t } from "../../Utils/helper";
import {
  CultureContext,
  ICultureContextType,
} from "../../Context/cultureContext";

export const LanguageMenu = () => {
  const { redirect } = useRelocate();
  const { locale, region } = useParams();

  const cookies = new Cookies();
  const { t, i18n } = useTranslation("common");

  const { languages, config } = React.useContext(
    CultureContext
  ) as ICultureContextType;

  const handleLangChange = (url: string, languageCode: string) => {
    // update i18n language
    i18n.changeLanguage(languageCode);

    // update browser language cookie
    if (cookies.get("language")) {
      cookies.remove("language");
    }
    let date = new Date();
    date.setTime(date.getTime() + 24 * 60 * 60 * 100);
    cookies.set("language", languageCode, {
      path: "/",
      expires: date,
    });

    // relocate path
    redirect(url);
  };

  if (languages) {
    return (
      <FormControl className="without-outline-select lang-form">
        <Typography component="span" sx={{ fontSize: ".8rem" }}>
          {t("header.language")}
        </Typography>
        <FlagMenu
          languages={languages}
          locale={locale}
          changeLanguage={handleLangChange}
          config={config}
          region={region}
        />
      </FormControl>
    );
  }

  return <></>;
};

interface IFlagInterface {
  languages: any;
  locale: string | undefined;
  changeLanguage: any;
  config: any;
  region: string | undefined;
}

export const FlagMenu = ({
  languages,
  locale = "en",
  changeLanguage,
  config,
  region,
}: IFlagInterface) => {
  const { urlWithoutLocalParam } = useRelocate();
  const urlhalf = urlWithoutLocalParam(window.location);
  const [configApi, setConfigApi] = useState<any>();
  const urlhost = window.location.href.includes("cookie-policy")
    ? `/${region}${urlhalf}`
    : `/${region}${urlhalf}`;
  const fetchConfigApi = async () => {
    const configRes = await MyConfig();
    setConfigApi(configRes);
  };
  useEffect(() => {
    fetchConfigApi();
  }, []);

  return (
    <FormControl
      className="without-outline-select lang-form"
      style={{ marginLeft: "5px" }}
    >
      <Select
        id="languageMenu"
        label="Age"
        value={locale}
        MenuProps={{
          disableScrollLock: true,
        }}
      >
        <MenuItem disabled value="">
          {t("header.language", locale)}
        </MenuItem>
        {languages &&
          languages.map((language: any) => {
            return (
              <MenuItem
                key={language.technicalName}
                value={language.technicalName}
              >
                <a
                  href={`/${language.technicalName}${urlhost}`}
                  onClick={(e) => {
                    e.preventDefault();
                    changeLanguage(
                      `/${language.technicalName}${urlhost}`,
                      language.technicalName
                    );
                    return false;
                  }}
                  hrefLang={language.technicalName}
                >
                  {configApi !== undefined && (
                    <span className="flag-container">
                    <img
                      src={`${configApi.REACT_APP_BIMDATABASE_API_URL}${language.thumbnailUrl}`}
                      alt={language.longName}
                      className="country-flag"
                      id="flag"
                      />
                      </span>
                  )}
                  {/* <img
                    src={`${config.REACT_APP_BIMDATABASE_API_URL}${language.thumbnailUrl}`}
                    alt={language.longName}
                    className="country-flag"
                    width="25px"
                    height="22px"
                  /> */}
                  <Typography variant="body1">
                    {language.translation}
                  </Typography>
                </a>
              </MenuItem>
            );
          })}
      </Select>
    </FormControl>
  );
};
